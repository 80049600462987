/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

// shippingAddress: {
//   revision: 0,
//   regionCode: 'IT',
//   languageCode: 'it_IT',
//   postalCode: '31021',
//   sortingCode: '',
//   administrativeArea: 'TV',
//   locality: 'MOGLIANO VENETO',
//   sublocality: '',
//   addressLines: ['Via Raffaello Sanzio 31  ', ''],
//   recipients: ['{"firstname":"COLFERT S.p.A.","lastname":"--"}'],
//   organization: '',
//   phoneNumber: '04221499',
//   additionalInfo: null,
// },

type ShippingAddressRest = {
  revision: number;
  regionCode: string;
  languageCode: string;
  postalCode: string;
  sortingCode: string;
  administrativeArea: string;
  locality: string;
  sublocality: string;
  addressLines: string[];
  recipients: string[]; // Assuming recipients are JSON strings
  organization: string;
  phoneNumber: string;
  additionalInfo: string | null;
};

export const shippingAddressIsPickup = (shippingAddress: ShippingAddressRest) => {
  const COLFERT_FIRSTNAME = 'COLFERT S.p.A.';
  const COLFERT_LASTNAME = '--';
  try {
    const parsedRecipient = JSON.parse(shippingAddress.recipients[0]);
    return parsedRecipient.firstname === COLFERT_FIRSTNAME && parsedRecipient.lastname === COLFERT_LASTNAME;
  } catch (error) {
    console.error('shippingAddressIsPickup ~ error:', error);
  }
  return false;
};
