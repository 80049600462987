/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Aggregation, CategoryTree } from '@gemini-vsf/api-client';
import { categoryGetters } from '@gemini-vsf/composables';
// eslint-disable-next-line import/no-extraneous-dependencies
import { RawLocation } from 'vue-router';
import { Breadcrumb, Alternate, Canonical, SelectableAvailableFilter } from '~/types/types';
import { generateBreadcrumbStructuredData } from '~/helpers/seo/structuredData';

/**
 * Filters an array of facets based on a list of available facet attribute codes.
 *
 * @param {Aggregation[]} facets - The array of facets to filter.
 * @param {string[]} availableFacets - The list of available facet attribute codes.
 * @returns {Aggregation[]} The filtered array of facets.
 */
export const filterFacets = (facets: Aggregation[], availableFacets: string[]): Aggregation[] =>
  facets?.filter((facet) => availableFacets.includes(facet.attribute_code));

/**
 * Generates an array of breadcrumbs based on the provided category data and path.
 *
 * @param {CategoryTree} categoryData - The category data to generate breadcrumbs for.
 * @param {(route: RawLocation, locale?: string) => string} localePath - A function to generate a localized path for a route.
 * @param {string} path - The path to be used for generating breadcrumbs.
 * @returns {Breadcrumb[]} An array of breadcrumbs. Each breadcrumb is an object with `text` and `link` properties.
 */
export const generateBreadcrumbs = (
  categoryData: CategoryTree,
  localePath: (route: RawLocation, locale?: string) => string,
  path: string
): Breadcrumb[] => {
  let accumulatedPath = '';
  return [
    {
      text: 'HOME',
      link: localePath('/'),
    },
    ...path
      .split('/')
      .slice(2, -1)
      .map((c) => {
        accumulatedPath += `/${c}`;
        return { text: c.replaceAll('-', ' ').toUpperCase(), link: localePath(accumulatedPath) };
      }),
    ...(categoryData.name ? [{ text: categoryData.name, link: localePath(categoryGetters.getSlug(categoryData)) }] : []),
  ].filter((breadcrumb) => breadcrumb.link !== '/it/marchi');
};

/**
 * Generates an object with SEO data based on the provided category data.
 *
 * @param {CategoryTree} categoryData - The category data to generate SEO data for.
 * @param {Alternate[]} alternates - An array of alternate links to generate SEO data for.
 * @returns {Record<string, unknown>} An object with SEO data.
 */
export const generateSeoData = (
  categoryData: CategoryTree,
  alternates: Alternate[],
  breadcrumbs: Breadcrumb[],
  localePath: (route: RawLocation, locale?: string) => string,
  storeUrl: string,
  canonical: Canonical
): Record<string, unknown> => ({
  title: categoryData.meta_title || categoryData.name || 'Colfert SpA',
  meta: [
    {
      hid: 'description',
      name: 'description',
      content: categoryData.meta_description,
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: categoryData.meta_keywords,
    },
    {
      hid: 'og:title',
      property: 'og:title',
      content: categoryData.meta_title,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: categoryData.meta_description,
    },
  ],
  link: [...alternates, canonical],
  script: [
    {
      type: 'application/ld+json',
      json: generateBreadcrumbStructuredData(breadcrumbs, 'gemini', localePath, storeUrl),
    },
  ],
});

/**
 * Removes a parameter from the URL.
 *
 * @param {string} filterName - The name of the filter to remove.
 * @param {string} filterValue - The value of the filter to remove.
 * @param {string} path - The path of the URL.
 */
export const removeParameterFromUrl = (filterName: string, filterValue: string, path: string) => {
  if (typeof window === 'undefined') return;
  const {
    history,
    location: { search },
  } = window;
  const urlParams = new URLSearchParams(search);
  const newUrlParams = new URLSearchParams();
  urlParams.forEach((paramValue, paramKey) => {
    if (!(paramKey === filterName && paramValue === filterValue)) {
      newUrlParams.append(paramKey, paramValue);
    }
  });
  history.pushState({}, '', `${path}?${newUrlParams.toString()}`);
};

/**
 * Deletes a parameter from the URL.
 *
 * @param {string} filterName - The name of the filter to delete.
 * @param {string} path - The path of the URL.
 */
export const deleteParameterFromUrl = (filterName: string, path: string) => {
  if (typeof window === 'undefined') return;
  const {
    history,
    location: { search },
  } = window;
  const urlParams = new URLSearchParams(search);
  urlParams.delete(filterName);
  history.pushState({}, '', `${path}?${urlParams.toString()}`);
};

/**
 * Replaces a parameter in the URL.
 *
 * @param {string} filterName - The name of the filter to replace.
 * @param {string} filterValue - The new value of the filter.
 * @param {string} path - The path of the URL.
 */
export const replaceParameterInUrl = (filterName: string, filterValue: string, path: string) => {
  if (typeof window === 'undefined') return;
  const {
    history,
    location: { search },
  } = window;
  const urlParams = new URLSearchParams(search);
  urlParams.set(filterName, filterValue);
  history.pushState({}, '', `${path}?${urlParams.toString()}`);
};

/**
 * Adds a parameter to the URL.
 *
 * @param {string} filterName - The name of the filter to add.
 * @param {string} filterValue - The value of the filter to add.
 * @param {string} path - The path of the URL.
 */
export const addParameterToUrl = (filterName: string, filterValue: string, path: string) => {
  if (typeof window === 'undefined') return;
  const {
    history,
    location: { search },
  } = window;
  const urlParams = new URLSearchParams(search);
  urlParams.append(filterName, filterValue);
  history.pushState({}, '', `${path}?${urlParams.toString()}`);
};

/**
 * Removes all parameters from the URL.
 *
 * @returns {void}
 */
export const removeAllParametersFromUrl = (): void => {
  if (typeof window === 'undefined') return;
  const {
    history,
    location: { pathname },
  } = window;
  history.replaceState(null, '', pathname);
};

/**
 * Generates an array of selectable available filters based on the provided available filters and current filters.
 *
 * @param {Aggregation[]} availableFilters - The available filters.
 * @param {Record<string, string[]>} currentFilters - The current filters.
 * @returns {SelectableAvailableFilter[]} An array of selectable available filters. Each filter is an object with `label` and `options` properties.
 */
export const generateSelectableAvailableFilters = (
  availableFilters: Aggregation[],
  currentFilters: Record<string, string[]>
): SelectableAvailableFilter[] =>
  availableFilters
    ?.map((filter: Aggregation) => ({
      label: filter.attribute_code,
      options: filter.options.map((o) => ({
        label: o.label,
        value: o.value,
        count: o.count,
        active: currentFilters[filter.attribute_code]?.includes(o.value),
      })),
    }))
    .filter((selectableFilter) => selectableFilter.options.length > 0)
    .sort((a, b) => a.label.localeCompare(b.label));

/**
 * Updates the selected filters based on the provided filter name and value.
 *
 * @param {Aggregation[]} selectableAvailableFilters - The selectable available filters.
 * @param {string} filterName - The name of the filter to update.
 * @param {string} filterValue - The value of the filter to update.
 * @param {boolean} filterExists - A boolean indicating whether the filter exists.
 * @returns {Aggregation[]} The updated selectable available filters.
 */
export const updateSelectedFilters = (selectableAvailableFilters: Aggregation[], filterName: string, filterValue: string, filterExists: boolean) =>
  selectableAvailableFilters.map((selectableAvailableFilter) => {
    if (selectableAvailableFilter.label === filterName) {
      return {
        ...selectableAvailableFilter,
        options: selectableAvailableFilter.options.map((option) => {
          if (option.value === filterValue) {
            return {
              ...option,
              active: !filterExists,
            };
          }
          return option;
        }),
      };
    }
    return selectableAvailableFilter;
  });

/**
 * Scrolls to the top of the page.
 *
 * @returns {void}
 */
export const scrollToTop = (): void => {
  if (typeof window === 'undefined') return;
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};
